<template>
  <v-card>
    <v-toolbar dark color="primary">
      <v-btn icon dark @click.native="close">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title>Keranjang Belanja Anda!</v-toolbar-title>
    </v-toolbar>
    <v-divider></v-divider>
    <v-container fluid class="px-md-16">
      <div v-if="countCart === 0">
        <v-alert outlined color="warning" icon="mdi-cart-off">
          Keranjang belanja kosong!
        </v-alert>
      </div>
      <v-list three-line v-if="countCart > 0" class="px-md-16">
        <template v-for="(item, index) in carts">
          <v-list-item :key="'cart' + index">
            <v-list-item-avatar>
              <v-img :src="getImage(item.image)"></v-img>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title
                class="
                  text-caption
                  text-sm-body-2
                  text-md-body-1
                  text-lg-body-1
                  text-xl-body-1
                "
                v-html="
                  '<b>' +
                  item.brand +
                  ' ' +
                  item.type +
                  ' ' +
                  item.product_name +
                  ' (' +
                  item.product_code +
                  ')</b>'
                "
              ></v-list-item-title>
              <v-list-item-subtitle
                class="
                  text-caption
                  text-sm-body-3
                  text-md-body-2
                  text-lg-body-2
                  text-xl-body-2
                "
              >
                <small
                  >Kategori {{ item.category }} | Satuan :
                  {{ item.unit }}</small
                >
                <span style="float: right">
                  <v-container>
                    <v-row>
                      <v-col cols="2 offset-5">
                        <v-btn
                          icon
                          small
                          rounded
                          depressed
                          class="mr-2 mb-1"
                          @click.stop="removeCart(item)"
                        >
                          <v-icon dark color="error">mdi-minus-circle</v-icon>
                        </v-btn>
                      </v-col>
                      <v-col cols="3">
                        <v-text-field
                          class="inputPrice"
                          min="1"
                          v-model.number="item.quantity"
                          type="number"
                          dense
                          :rules="rules"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="2">
                        <v-btn
                          icon
                          small
                          rounded
                          depressed
                          class="ml-2 mb-1"
                          @click.stop="addCart(item)"
                        >
                          <v-icon dark color="success">mdi-plus-circle</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-container>
                  <!-- <v-btn
                    icon
                    small
                    rounded
                    depressed
                    class="mr-2 mb-1"
                    @click.stop="removeCart(item)"
                  >
                    <v-icon dark color="error">mdi-minus-circle</v-icon>
                  </v-btn> -->
                  <!-- <span
                    class="
                      text-caption
                      text-sm-body-2
                      text-md-body-1
                      text-lg-body-1
                      text-xl-body-1
                    "
                    ><b>{{ item.quantity }}</b></span
                  > -->
                  <!-- <v-text-field v-model="item.quantity" dense style="width:10%">
                  </v-text-field>
                  <v-btn
                    icon
                    small
                    rounded
                    depressed
                    class="ml-2 mb-1"
                    @click.stop="addCart(item)"
                  >
                    <v-icon dark color="success">mdi-plus-circle</v-icon>
                  </v-btn> -->
                </span>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-divider :key="'cart' + index"></v-divider>
        </template>
      </v-list>
      <v-card>
        <v-card-text>
          <v-layout wrap>
            <v-flex pa-1 xs6>
              <!-- Total Price ({{ totalQuantity }} items)<br /> -->
              <span class="title">Total {{ totalQuantity }} Items</span>
            </v-flex>
            <v-flex pa-1 xs6 text-right>
              <v-btn
                color="primary"
                @click="checkoutConfirm"
                :disabled="totalQuantity == 0"
              >
                <v-icon>mdi-cart-arrow-right</v-icon> &nbsp; Checkout
              </v-btn>
            </v-flex>
          </v-layout>
        </v-card-text>
      </v-card>
    </v-container>

    <v-dialog v-model="dialogConfirm" max-width="500px">
      <v-card v-if="guest">
        <v-card-title class="text-h5">Login First!</v-card-title>
        <v-card-text>
          Silahkan login terlebih dahulu untuk melakukan checkout
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeConfirm">Tutup</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
      <v-card v-if="!guest">
        <v-card-title class="text-h5">Konfirmasi!</v-card-title>
        <v-card-text>
          Apakah anda yakin melakukan checkout untuk pesanan ini?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeConfirm">Batal</v-btn>
          <v-btn color="blue darken-1" text @click="checkout">Ya</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "cart",
  data: () => ({
    dialogConfirm: false,
    rules: [
      (v) => !!v || "*required"
    ],
  }),
  computed: {
    ...mapGetters({
      carts: "cart/carts",
      countCart: "cart/count",
      totalPrice: "cart/totalPrice",
      totalQuantity: "cart/totalQuantity",
      totalWeight: "cart/totalWeight",
      user: "auth/user",
      guest: "auth/guest",
    }),
  },
  methods: {
    ...mapActions({
      setAlert: "alert/set",
      addCart: "cart/add",
      removeCart: "cart/remove",
      setCart: "cart/set",
    }),
    isiCart(item) {
      console.log(item);
    },
    checkoutConfirm() {
      this.dialogConfirm = true;
    },
    closeConfirm() {
      this.dialogConfirm = false;
    },
    checkout() {
      this.closeConfirm();
      // this.close();
      // this.$router.push({ path: "/checkout" });
      // console.log(this.user.data.user_id);
      // console.log(this.carts);
      this.$vloading.show();
      // let config = {
      //   headers: {
      //     Authorization: "Bearer " + this.user.data.access_token,
      //   },
      // };
      // var encodedCart = JSON.stringify(this.carts);
      // console.log(encodedCart);
      // let formData = new FormData();
      // formData.set("user_id", this.user.data.user_id);
      // formData.set("costumer_id", this.user.data.user_id);
      // formData.set("carts", encodedCart);
      // this.axios.post("/transaction", formData, config);

      let encodedCart = JSON.stringify(this.carts);
      //console.log(encodedCart)
      let formData = new FormData();
      formData.set("user_id", this.user.data.user_id);
      formData.set("costumer_id", this.user.data.user_id);
      formData.set("carts", encodedCart);
      formData.set("division_id", this.user.data.division_id);
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.data.access_token,
        },
      };
      this.axios
        .post("/transaction", formData, config)
        .then(() => {
          this.$vloading.hide();
          // console.log(response);
          this.setAlert({
            status: true,
            color: "success",
            text: "Transaksi Berhasil!",
          });
          this.close();
          this.setCart([]);
          // this.$router.replace({ name: "Home" });
        })
        .catch((error) => {
          this.$vloading.hide();
          // console.log(error.response.data.messages);
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data.messages,
          });
          this.close();
        });
    },
    close() {
      this.$emit("closed", false);
    },
  },
};
</script>
<style scoped>
.inputPrice >>> input[type="number"] {
  -moz-appearance: textfield;
}
.inputPrice >>> input::-webkit-outer-spin-button,
.inputPrice >>> input::-webkit-inner-spin-button {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}
</style>